







































































































































































































































































































































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import { Input, Button, Form } from "element-ui";
import { AdminRouter } from "@/utils/routePathContsant";
import commonstore from "@/store/modules/common";
import DetailTag from "@/components/UIComponents/DetailTag.vue";
import {
  GPSServiceProviderModule as gpsServiceStore,
  VehicleModule as vehicleStore,
  MetaModule as metaStore,
} from "@/store/modules";
import ownershipStore from "@/store/modules/ownership";
import { GpsDevice } from "@/store/models/registration/registration";
import { VehicleType } from "@/store/models/meta";
import NepaliDatePicker from "@/components/NepaliDatePicker.vue";
import { BSToAD } from "bikram-sambat-js";
import helpers from "@/utils/helpers";

@Component({
  components: {
    Input,
    Button,
    Form,
    DetailTag,
    NepaliDatePicker,
  },
})
export default class New extends Vue {
  isCreate: boolean = true;
  devices: GpsDevice[] = [];
  vehicleType: VehicleType[] = [];
  vehicleOwnerId: number = 0;
  remarks: string = "";
  location: string = "";
  dateBS: string = "";
  dateAD: string = "";
  time: string = "";
  role: string | undefined = "";
  public centerPhysicalDialogVisible = false;
  public centerRescheduleDialogVisible = false;
  public centerApproveDialogVisible = false;
  public centerRejectDialogVisible = false;
  data() {
    return {
      options: [
        {
          value: "Commercial",
          label: "Commercial",
        },
        {
          value: "Non-Commercial",
          label: "Non-Commercial",
        },
      ],
      value: "",
    };
  }

  momentTime(time: any) {
    return helpers.toMomentTime(time);
  }

  get AdminRouter() {
    return AdminRouter;
  }

  get vehicle() {
    return vehicleStore.vehicle;
  }

  bsDateChange(e: any) {
    if (e && e !== null) {
      this.dateAD = BSToAD(e);
    }
  }

  handleChange(file: any, fileList: any) {
    let vm = this;
    vm.vehicle.documents.vehicleTaxPaymentReceipt = fileList[0].raw;
  }

  handleChange1(file: any, fileList: any) {
    let vm = this;
    vm.vehicle.documents.vehicleInsuranceCertificate = fileList[0].raw;
  }

  handleChange2(file: any, fileList: any) {
    let vm = this;
    vm.vehicle.documents.vehicleTestingCertificate = fileList[0].raw;
  }

  handleChange3(file: any, fileList: any) {
    let vm = this;
    vm.vehicle.documents.blueBook1 = fileList[0].raw;
  }

  handleChange4(file: any, fileList: any) {
    let vm = this;
    vm.vehicle.documents.blueBook2 = fileList[0].raw;
  }

  handleChange5(file: any, fileList: any) {
    let vm = this;

    vm.vehicle.routePermits[0].routePermitCertificate = fileList[0].raw;
  }

  handleChange6(file: any, fileList: any) {
    let vm = this;
    vm.vehicle.documents.vehicleTaxPaymentReceipt = fileList[0].raw;
  }

  handleChange7(file: any, fileList: any) {
    let vm = this;
    vm.vehicle.documents.transportCompanyCertificate = fileList[0].raw;
  }

  addRoutePermit() {
    this.vehicle.routePermits.push({
      id: 0,
      route: 0,
      routePermitCertificate: "",
      isActive: true,
      issuedDate: "",
      issuedDateBs: "",
      expiryDate: "",
      expiryDateBs: "",
    });
  }

  deleteRoutePermit(counter: any) {
    this.vehicle.routePermits.splice(counter, 1);
  }

  async submit() {
    let isValid = await this.$validator.validateAll();
    if (!isValid) {
      return;
    }
    let vm = this;
    commonstore.showLoading();
    if (this.vehicle.id) {
      // Remove File Fields without file
      this.removeFiles(this.vehicle.routePermits, "routePermitCertificate");
      this.removeFiles(this.vehicle.documents, "vehicleTestingCertificate");
      this.removeFiles(this.vehicle.documents, "vehicleInsuranceCertificate");
      this.removeFiles(this.vehicle.documents, "vehicleTaxPaymentReceipt");
      this.removeFiles(this.vehicle.documents, "blueBookPageOne");
      this.removeFiles(this.vehicle.documents, "blueBookPageTwo");
      this.removeFiles(this.vehicle.documents, "citizenId");
      this.removeFiles(this.vehicle.documents, "transportCompanyCertificate");
      this.removeFiles(this.vehicle.documents, "license");
    }
    let i = this.vehicleOwnerId;

    await vehicleStore.approveVehicle(this.vehicle.id);
    this.$snotify.success("Vehicle Approved Successfully");
    commonstore.hideLoading();
    if (this.vehicleOwnerId && this.vehicleOwnerId != 0) {
      this.$router.push(
        AdminRouter.VehicleApproval + "?vehicle_owner=" + this.vehicleOwnerId
      );
    } else {
      this.$router.push(AdminRouter.AllVehicleList);
    }
  }

  async cancelForm() {
    if (this.vehicleOwnerId && this.vehicleOwnerId != 0) {
      this.$router.push(
        AdminRouter.VehicleApproval + "?vehicle_owner=" + this.vehicleOwnerId
      );
    } else {
      this.$router.push(AdminRouter.AllVehicleList);
    }
  }

  approveForm() {
    let vm = this;
    this.$confirm(`Are you sure you want to Approve?`)
      .then((_) => {
        vehicleStore.approveVehicle(this.vehicle.id);
        this.$snotify.success("Approved Successfully");
        this.$router.push(AdminRouter.AllVehicleList);
      })
      .catch((_) => {});
  }

  async created() {
    vehicleStore.resetField();
    let vm = this;
    this.vehicleOwnerId = commonstore.vehicleOwnerId;
    this.role = helpers.getUserType();
    let a = helpers.getUserProfile();

    const id = vm.$route.query["id"];
    await gpsServiceStore.loadActiveGpsServiceProvider();
    await metaStore.loadRoutesDD();
    await metaStore.loadVehicleTypeCodeDD();
    await metaStore.loadEngineModel();

    if (id) {
      vm.isCreate = false;

      await vehicleStore.getVehicleById(id);
    } else {
      vehicleStore.resetField();
    }

    if (this.vehicle.gpsServiceProvider) {
      await gpsServiceStore.loadGpsDevice(this.vehicle.gpsServiceProvider);
      this.devices = gpsServiceStore.gpsDeviceList;
    }
  }

  async onPhysicalCheck(reshedule = false) {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      let vm = this;
      commonstore.showLoading();
      const id = vm.$route.query["id"];
      await vehicleStore.physicalCheckVehicles({
        id: id,
        remarks: this.remarks,
        location: this.location,
        date: this.dateAD,
        nepaliDate: this.dateBS,
        time: this.time,
        reshedule: reshedule,
      });

      commonstore.hideLoading();
      this.$router.push(AdminRouter.AllVehicleList);
    }
  }

  async onReject() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      let vm = this;
      commonstore.showLoading();
      const id = vm.$route.query["id"];
      await vehicleStore.rejectVehicles({ id: id, remarks: this.remarks });
      commonstore.hideLoading();
      this.$router.push(AdminRouter.AllVehicleList);
    }
  }

  onApprove() {}

  removeFiles(property: any, fieldName: any) {
    let field = property[fieldName];
    if (field === undefined || field === null || typeof field === "string") {
      delete property[fieldName];
    }
  }

  async onGpsServiceProviderChange(e: any) {
    await gpsServiceStore.loadGpsDevice(e);
    this.devices = gpsServiceStore.gpsDeviceList;
  }

  async onVehicleTypeChange(e: any) {
    this.vehicleType = await metaStore.loadVehicleTypeDD(e);
  }

  get serviceProvider() {
    let a = gpsServiceStore.gpsServiceProviderList;
    return gpsServiceStore.gpsServiceProviderList;
  }

  get routes() {
    return metaStore.routesList;
  }

  get engineModel() {
    return metaStore.engineModelList;
  }

  get vehicleTypeCode() {
    return metaStore.vehicleTypeCodeList;
  }

  mounted() {}
  updated() {}
}
